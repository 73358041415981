<script setup lang="ts">
const props = defineProps<{
  size?: "xs" | "sm" | "base" | "lg";
  color?: string | null; // allows hexColor | HSL
  username?: string;
  speakerId?: number;
  src?: string | null;
}>();

const username = computed(() => {
  return (props.username || "")
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .substring(0, 2);
});

const SPEAKER_COLOR = [
  "#FFFFE0",
  "#FFB6C1",
  "#B0C4DE",
  "#FFDAB9",
  "#D8BFD8",
  "#FFD700",
  "#1E90FF",
  "#FFA500",
  "#FF4500",
  "#AFEEEE",
  "#E6E6FA",
  "#E0FFFF",
  "#DDA0DD",
  "#FFA07A",
  "#9370DB",
  "#FFDAB9",
  "#FA8072",
  "#FFFF00",
  "#FFD700",
  "#D2B48C",
];

const backgroundColor =
  (props.speakerId ? SPEAKER_COLOR?.[props.speakerId] : props.color) || "#333";
</script>

<template>
  <span
    class="relative inline-flex shrink-0 items-center justify-center rounded-full transition"
    :class="{
      'size-5 text-xs': props.size === 'xs',
      'size-8 text-sm': props.size === 'sm' || !props.size,
      'size-7 text-sm sm:size-10 sm:text-lg': props.size === 'base',
      'size-12 text-xl': props.size === 'lg',
    }"
    :style="{ backgroundColor }"
  >
    <img
      v-if="!props.color && !props.speakerId"
      :src="props.src || '/favicon.png'"
      class="rounded-full"
    />
    <span class="truncate font-medium leading-none text-gray-50">
      {{ username || "U" }}
    </span>
  </span>
</template>
